export const getOverviewData = () => {
  return [
    {
      // sectionImage: 'Overview/credit-one-bank-club-logo.png',
      sectionTitle: "Raiders Locker Room",
      paragraph:
        "Step into the breathtaking Raiders locker room, a space that offers a unique behind-the-scenes glimpse into the game day experience of professional players. Adorned with inspiring quotes from Al Davis, this venue encapsulates the spirit and history of the team, making it an extraordinary setting for your event.  It is perfectly suited for small receptions, meetings, and intimate dinners, the locker room provides an exclusive atmosphere that encourages connection and engagement. Host your guests in a setting that celebrates the legacy of the team while offering a memorable and personal experience that they will never forget.",
      area: "6,100",
      reception: "60",
      banquet: "60",
    },
  ];
};

export const getCarouselImages = () => {
  return [
    "RaidersLockerRoom/Photos/raiders-locker-room-01.jpg",
    "RaidersLockerRoom/Photos/raiders-locker-room-02.jpg",
    "RaidersLockerRoom/Photos/raiders-locker-room-03.jpg",
  ];
};

export const getLayoutImages = () => {
  return ["RaidersLockerRoom/Layout/layout-raiders-locker-room.jpg"];
};

export const getEventPhotos = () => {
  return [
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-1.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-2.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-3.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-4.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-5.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-6.jpg",
    "RaidersLockerRoom/EventsNew/raiders-locker-room-event-7.jpg",
  ];
};
