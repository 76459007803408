<template>
  <section id="EventSpacesCarousel">
    <div class="menu-underlay"></div>
    <b-carousel
      v-if="carouselImages.length > 1"
      id="carousel-1"
      :interval=0
      indicators
      controls
    >
      <b-carousel-slide
        v-for="(carouselImage, index) in carouselImages"
        :key="'carouselImage' + index"
      >
        <template v-slot:img>
          <img
            :src="require('@/assets/images/Carousel/' + carouselImage)"
            :alt="'image slot' + index"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
    <b-carousel
      v-else
      id="carousel-1"
    >
      <b-carousel-slide
        v-for="(carouselImage, index) in carouselImages"
        :key="'carouselImage' + index"
      >
        <template v-slot:img>
          <img
            :src="require('@/assets/images/Carousel/' + carouselImage)"
            :alt="'image slot' + index"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
  </section>    
</template>

<script>
  export default {
    name: "EventSpacesCarousel",
    props: {
      carouselImages: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>
  #EventSpacesCarousel {
    .menu-underlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 268px;
      height: 100%;
      box-shadow: 8px 0 8px 0 rgba(0,0,0,0.50);
      background-image: url('~@/assets/images/Carousel/generic-gallery-side-bg.jpg');
      z-index: 2;
      @media (max-width: 993px) {
        display: none;
      }
    }
  }
</style>

<style lang="scss">
  /* Bootstrap Overrides */
  #EventSpacesCarousel {
    #carousel-1 {
      overflow: hidden;
      height: 100vh;
      width: 100vw;
      @media (max-width: 993px) {
        height: auto;
      }

      .carousel-inner img {
        display: block;
        object-fit: cover;
        height: 100vh;
        width: calc(100vw - 268px);
        margin-left: 268px;
        @media (max-width: 993px) {
          height: auto;
          width: 100vw;
          margin-left: 0;
          object-fit: contain;
        }
      }
      .carousel-control-next, .carousel-control-prev {
        width: 70px;
        height: 70px;
        top: 50%;
        background-image: linear-gradient(180deg, #525252 0%, #1F1F1F 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 0 6px 6px 0;
        opacity: 1;
        @media (max-width: 993px) {
          top: 45%;
          width: 50px;
          height: 50px;
        }
        @media (max-width: $sm) {
          top: 40%
        }
      }
      .carousel-control-next {
        right: 0;
        transform: scaleX(-1);
      }
      .carousel-control-prev {
        left: 268px;
        @media (max-width: 993px) {
          left: 0px;
        }
      }

      .carousel-control-next-icon, .carousel-control-prev-icon {
        background-image: url("~@/assets/icons/Carousel/Chevron-right-icon.svg");
        width: 28.3px;
        height: 28.3px;
        @media (max-width: 993px) {
          width: 23px;
          height: 23px;
        }
      }
      .carousel-control-next-icon {
        transform: scaleX(-1);
      }
      .carousel-control-prev-icon {
        transform: scaleX(-1);
      }

      .carousel-indicators {
        background-image: linear-gradient(180deg, #525252 0%, #1F1F1F 100%);
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
        border-radius: 6px;
        width: auto;
        max-width: 40%;
        min-width: 10%;
        height: 34px;
        padding: 14px 23px 16px 23px;
        bottom: 10px;
        left: calc(40% + 268px);
        right: auto;
        transform: translate(-50%);
        z-index: 1;
        @media (max-width: 993px) {
          left: 50%;
          bottom: 2%;
          height: 16px;
          border-radius: 8px;
          padding: 6px 13px 7px 13px;
        }

        li {
          width: 20px;
          height: 4px;
          background: #9d9d9d;
          box-shadow: 0 0 8px 0 rgba(255,255,255,0.50);
          border: 0;
          border-radius: 2px;
          border-radius: 2px;
          @media (max-width: 993px) {
            height: 3px;
            width: 12px;
          }
        }
        .active {
          opacity: 0.8;
          background: #FFFFFF;
          box-shadow: 0 0 8px 0 rgba(255,255,255,0.50);
        }
      }
    }
  }
</style>